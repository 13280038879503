import React from "react"
import { Link } from "gatsby"
import Layout from "./../components/layouts/Default"
import RequestLeftCol from "./../components/layouts/partials/pages/request/RequestLeftCol"

export default function Page({ data, location }) {
  const svg = {
    shield: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="inline transition-colors duration-300"
        width="64"
        height="64"
        viewBox="0 0 24 24"
        strokeWidth="1"
        stroke="#000000"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z"></path>
        <path d="M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3"></path>
      </svg>
    ),
  }

  return (
    <Layout
      title="Anfrage gesendet"
      description="Vielen Dank für Deine Anfrage. Wir werden uns zeitnah bei Dir melden."
      location={location}
      smallFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <div className="flex flex-wrap">
              <div className="w-full mb-5 lg:hidden">
                <Link to="/" className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#1939ec"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="8" y1="12" x2="16" y2="12" />
                    <line x1="8" y1="12" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="12" y2="8" />
                  </svg>{" "}
                  Zur Startseite
                </Link>
              </div>
              <div className="w-full mb-5">
                <h1 className="mb-5 text-2xl font-bold md:text-3xl lg:text-4xl">
                  Anfrage erfolgreich versendet
                </h1>
                <p className="mb-5">
                  Vielen Dank für Deine Anfrage. Wir werden uns zeitnah bei Dir
                  melden.
                </p>
                <Link
                  to="/"
                  className="inline-block px-3 py-3 text-lg text-center text-white transition duration-300 ease-in-out rounded shadow btn bgh-gradient"
                >
                  Zurück zur Startseite
                </Link>
              </div>
            </div>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
